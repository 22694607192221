/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum UploadRoles {
  INACTIVATE = 'INACTIVATE',
  RECHARGE = 'RECHARGE_V2',
  LIMIT_UPDATE_SCHEDULING = 'LIMIT_UPDATE_SCHEDULING_V2',
}

export enum UploadStatus {
  PROCESSING = 'PROCESSING',
  PROCESSED_SUCCESS = 'PROCESSED_SUCCESS',
  PROCESSED_ERROR = 'PROCESSED_ERROR',
}

export enum UploadStatusPt {
  PROCESSING = 'Processando',
  PROCESSED_SUCCESS = 'Sucesso',
  PROCESSED_ERROR = 'Erro',
}

export enum UploadRolesPt {
  INACTIVATE = 'Inativações',
  RECHARGE = 'Recargas',
  RECHARGE_V2 = 'Recargas',
  LIMIT_UPDATE_SCHEDULING = 'Agendamento de Limite Compra + Assaí',
  LIMIT_UPDATE_SCHEDULING_V2 = 'Agendamento de Limite Compra + Assaí',
}
