import { FC } from 'react';
import ModalFileValidation from './ModalFileValidation';

type Props = {
  isOpen: boolean;
  fileValidation: FileValidationResponse;
  handleClose(): void;
  handleConfirmUpload?(): Promise<void>;
};

const ModalFileValidationContainer: FC<Props> = ({
  isOpen,
  fileValidation,
  handleClose,
  handleConfirmUpload,
}) => {
  const hasErrors = Object.keys(fileValidation.errorDetails).length > 0;
  return (
    <ModalFileValidation
      fileValidation={fileValidation}
      hasErrors={hasErrors}
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirmUpload={handleConfirmUpload}
    />
  );
};

export default ModalFileValidationContainer;
