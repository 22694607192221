import { FC, useEffect, useState } from 'react';
import { ModalFileValidation, ModalUploadFile } from '~/components';
import { observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import {
  AlertMessages,
  UploadRoles,
  UserRoles,
  initialFileValidation,
  showAlert,
  useStores,
} from '~/utils';
import Uploads from './Uploads';

export const UploadsContainer: FC = () => {
  const navigate = useNavigate();
  const {
    user: { user },
    uploads: {
      uploadFile,
      getFiles,
      uploadsList,
      setCurrentFile,
      validateFile,
    },
  } = useStores();
  const [values, setValues] = useState<UploadFile>({} as UploadFile);
  const [onUploadFileModal, setOnUploadFileModal] = useState(false);
  const [onValidateFileModal, setOnValidateFileModal] = useState(false);
  const [isFilesLoading, setIsFilesLoading] = useState(true);
  const [fileValidation, setFileValidation] = useState<FileValidationResponse>(
    initialFileValidation,
  );

  const handleUploadFileModal = () =>
    setOnUploadFileModal((onUploadFileModal) => !onUploadFileModal);

  const handleValidateFileModal = () =>
    setOnValidateFileModal((onValidateFileModal) => !onValidateFileModal);

  const handleValidateFile = async (values: UploadFile) => {
    try {
      const validationResponse = await validateFile(values);
      setFileValidation(validationResponse);
      handleUploadFileModal();
      handleValidateFileModal();
    } catch (error) {
      if (error.response.data.errorDetails) {
        setFileValidation(error.response.data);
        handleUploadFileModal();
        handleValidateFileModal();
        return;
      }
      if (error.response.data.message) {
        showAlert({
          message: error.response.data.message,
          type: 'error',
        });
        return;
      }

      showAlert({
        message: AlertMessages.FILE_VALIDATION_UNKNOWN_ERROR,
        type: 'error',
      });
    }
  };

  const handleUploadFile = async (values: UploadFile) => {
    await uploadFile(values);
    onGetFiles({});
    showAlert({
      message: AlertMessages.FILES_UPLOAD_FILE,
      type: 'success',
    });
  };

  const handleFormSubmit = async (values: UploadFile) => {
    if (
      values.type === UploadRoles.LIMIT_UPDATE_SCHEDULING ||
      values.type === UploadRoles.RECHARGE
    ) {
      setValues(values);
      await handleValidateFile(values);
      return;
    }

    await handleUploadFile(values);
    handleUploadFileModal();
  };

  const onGetFiles = async (values: GetUsersProps) => {
    setIsFilesLoading(true);
    try {
      await getFiles(values);
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setIsFilesLoading(false);
    }
  };

  const handleFileDetails = async (values: FileUpload) => {
    setCurrentFile(values);
    navigate(Routes.FILES_DETAILS);
  };

  useEffect(() => {
    onGetFiles({});
  }, []);

  return (
    <>
      <Uploads
        isFilesLoading={isFilesLoading}
        filesList={uploadsList}
        isUserEspectator={user.role === UserRoles.SPECTATOR}
        onPressFile={handleFileDetails}
        onChangePage={(page) => onGetFiles({ page })}
        onPressUploadFile={handleUploadFileModal}
      />
      <ModalUploadFile
        isOpen={onUploadFileModal}
        handleClose={handleUploadFileModal}
        handleFormSubmit={handleFormSubmit}
      />
      <ModalFileValidation
        isOpen={onValidateFileModal}
        fileValidation={fileValidation}
        handleClose={handleValidateFileModal}
        handleConfirmUpload={async () => {
          await handleUploadFile(values);
          handleValidateFileModal();
        }}
      />
    </>
  );
};

export default observer(UploadsContainer);
