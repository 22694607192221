import { FC } from 'react';
import { faCheck, faExclamationTriangle, faXmark } from '~/assets';
import { formatShortDateFromString, formatToCurrency } from '~/utils';

import HeaderModal from '../HeaderModal';
import {
  ButtonComponent,
  Col,
  Content,
  Error,
  ErrorKey,
  ErrorValue,
  Errors,
  ErrorsIcon,
  ErrorsTitle,
  Footer,
  Icon,
  Input,
  ModalWrapper,
  Row,
  Wrapper,
} from './styles';

type Props = {
  isOpen: boolean;
  fileValidation: FileValidationResponse;
  hasErrors: boolean;
  handleConfirmUpload?(): Promise<void>;
  handleClose(): void;
};

const ModalFileValidation: FC<Props> = ({
  isOpen,
  handleClose,
  fileValidation,
  hasErrors,
  handleConfirmUpload,
}) => {
  return (
    <ModalWrapper>
      <Wrapper isOpen={isOpen}>
        <HeaderModal title="Validação do arquivo" />
        <Content>
          <Row>
            <Col>
              <Input
                id="importDate"
                name="importDate"
                label="Data da importação"
                value={formatShortDateFromString(fileValidation.importDate)}
                inputProps={{ readOnly: true }}
              />
            </Col>
            <Col>
              <Input
                id="successCount"
                name="successCount"
                label="Quantidade de registros"
                value={fileValidation.successCount}
                inputProps={{ readOnly: true }}
              />
            </Col>
          </Row>
          <Input
            id="importAmount"
            name="importAmount"
            label="Valor total da importação"
            value={formatToCurrency(fileValidation.importAmount)}
            inputProps={{ readOnly: true }}
          />

          {hasErrors && (
            <>
              <ErrorsTitle>
                <ErrorsIcon icon={faExclamationTriangle} />
                Atenção! O arquivo não será importado devido inconsistências.
              </ErrorsTitle>
              <Errors>
                {Object.entries(fileValidation.errorDetails).map(
                  ([key, value]) => (
                    <Error>
                      <ErrorKey>{key}:</ErrorKey>{' '}
                      <ErrorValue>{value}</ErrorValue>
                    </Error>
                  ),
                )}
              </Errors>
            </>
          )}
        </Content>

        <Footer>
          <ButtonComponent
            id="btn_cancel_upload"
            onPress={handleClose}
            transparent
          >
            <Icon icon={faXmark} />
            Cancelar
          </ButtonComponent>

          <ButtonComponent
            id="btn_confirm_upload"
            onPress={
              handleConfirmUpload ? handleConfirmUpload : () => undefined
            }
            onKeyEnter
            tertiary
            disabled={hasErrors}
          >
            <Icon icon={faCheck} />
            Confirmar envio
          </ButtonComponent>
        </Footer>
      </Wrapper>
    </ModalWrapper>
  );
};

export default ModalFileValidation;
